const menu = [
  {
    title: 'Home',
    icon: 'mdi-home',
    name: 'website',
    type: 'only',
    role: 'homes',
  },
  {
    title: 'Orders',
    icon: 'mdi-clipboard-text',
    name: 'website.orders',
    status: false,
    type: 'many',
    role: 'orders',
    children: [
      {
        title: 'All Orders',
        name: 'website.orders',
        role: 'orders',
      },
      {
        title: 'Abandoned Checkouts',
        name: 'website.abandoned',
        role: 'orders',
      },
    ],
  },
  {
    title: 'Products',
    icon: 'mdi-tag-multiple ',
    name: 'website.products',
    status: false,
    type: 'many',
    role: 'products',
    children: [
      {
        title: 'All Products',
        name: 'website.products',
        role: 'products',
      },
      {
        title: 'Collections',
        name: 'website.collections',
        role: 'products',
      },
      {
        title: 'Catalog',
        name: 'website.catalog',
        role: 'products',
      },
      {
        title: 'Inventory',
        name: 'website.inventory',
        role: 'products',
      },
      {
        title: 'Product Feeds',
        name: 'website.product-feeds',
        role: 'products',
      },
      {
        title: 'Personalized  Product',
        name: 'website.product-personalized',
        role: 'products',
      },
      {
        title: 'Bulk Updates',
        name: 'website.bulkupdates',
        role: 'products',
      },
      {
        title: 'Product Options',
        name: 'website.menufilter',
      },
      // {
      //   title: 'Test componet',
      //   name: 'website.products.dattest',
      // },
    ],
  },
  // {
  //   title: 'Catalog',
  //   icon: 'mdi-tag-multiple ',
  //   name: 'website.catalog',
  //   status: false,
  //   type: 'many',
  //   role: 'products',
  //   children: [
  //     {
  //       title: 'Catalog',
  //       name: 'website.catalog',
  //       role: 'products',
  //     },
  //     {
  //       title: 'Import list',
  //       name: 'website.import-list',
  //       role: 'products',
  //     },
  //   ],
  // },
  {
    title: 'Analytics',
    icon: 'mdi-signal-cellular-3',
    name: 'website.analytics',
    status: false,
    type: 'many',
    role: 'report',
    children: [
      {
        title: 'Analytics',
        name: 'website.analytics',
        role: 'dashboards',
      },
      {
        title: 'Sales Reports',
        name: 'website.sales-reports',
        role: 'sale_reports',
      },
      {
        title: 'Estimated Profit',
        name: 'website.estimated-profit',
        role: 'estimated_profit',
      },
      {
        title: 'UTM Analytics',
        name: 'website.utm-analystics',
        role: 'utm_analystics',
      },
    ],
  },
  {
    title: 'Discounts',
    icon: 'mdi-sale',
    status: false,
    type: 'many',
    role: 'discounts',
    name: 'website.discount.code',
    children: [
      {
        title: 'Code',
        name: 'website.discount.code',
        role: 'discounts',
      },
      {
        title: 'Automatic',
        name: 'website.discount.automatic',
        role: 'discounts',
      },
    ],
  },
  {
    title: 'Customer',
    icon: 'mdi-folder-account',
    name: 'website.customer',
    children: [],
    role: 'customers',
    type: 'only',
  },
  // {
  //   title: 'Share Pixel',
  //   icon: 'mdi-share',
  //   status: false,
  //   type: 'only',
  //   role: 'settings',
  //   name: 'website.pixel.sharePixel',
  // },
  {
    title: 'Apps',
    icon: 'mdi-puzzle',
    name: 'website.apps',
    type: 'only',
    role: 'apps',
  },
  {
    title: 'Settings',
    icon: 'mdi-settings',
    name: 'website.settings',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Online Store',
    icon: 'mdi-store',
    status: false,
    type: 'many',
    role: 'store',
    children: [
      {
        title: 'Themes',
        name: 'website.themes',
        role: 'themes',
      },
      {
        title: 'Pages',
        name: 'website.pages',
        role: 'pages',
      },
      {
        title: 'Navigation',
        name: 'website.menus',
        role: 'navigations',
      },
      {
        title: 'Domain',
        name: 'website.domain',
        role: 'domains',
      },
      {
        title: 'Preferences',
        name: 'website.preferences',
        role: 'preferences',
      },
      {
        title: 'Member',
        icon: 'mdi-account-group',
        name: 'website.member',
        type: 'only',
        role: 'homes',
      },
      {
        title: 'Size chart',
        name: 'website.sizechart',
        role: 'store',
      },
      // {
      //   title: 'DNS & Cache',
      //   name: 'website.dns',
      //   role: 'store',
      // },
    ],
  },
];
const dropMenu = [
  {
    title: 'Home',
    icon: 'mdi-home',
    name: 'website',
    type: 'only',
    role: 'homes',
  },
  {
    title: 'Orders',
    icon: 'mdi-clipboard-text',
    name: 'website.orders',
    status: false,
    type: 'many',
    role: 'orders',
    children: [
      {
        title: 'All Orders',
        name: 'website.orders',
        role: 'orders',
      },
      {
        title: 'Abandoned Checkouts',
        name: 'website.abandoned',
        role: 'orders',
      },
    ],
  },
  {
    title: 'Products',
    icon: 'mdi-cube ',
    name: 'website.products',
    status: false,
    type: 'many',
    role: 'products',
    children: [
      {
        title: 'All Products',
        name: 'website.products',
        role: 'products',
      },
      {
        title: 'Collections',
        name: 'website.collections',
        role: 'products',
      },
      {
        title: 'Catalog',
        name: 'website.catalog',
        role: 'products',
      },
      {
        title: 'Import AliExpress',
        name: 'website.apps.ali-connector.import',
        role: 'products',
      },
      {
        title: 'Reviews',
        name: 'website.apps.product-review.list',
        role: 'products',
      },
    ],
  },
  {
    title: 'Analytics',
    icon: 'mdi-signal-cellular-3',
    name: 'website.analytics',
    status: false,
    type: 'many',
    role: 'report',
    children: [
      {
        title: 'Analytics',
        name: 'website.analytics',
        role: 'dashboards',
      },
      {
        title: 'Sales Reports',
        name: 'website.sales-reports',
        role: 'sale_reports',
      },
      {
        title: 'Estimated Profit',
        name: 'website.estimated-profit',
        role: 'estimated_profit',
      },
      {
        title: 'UTM Analytics',
        name: 'website.utm-analystics',
        role: 'utm_analystics',
      },
    ],
  },
  {
    title: 'Discounts',
    icon: 'mdi-sale',
    status: false,
    type: 'many',
    role: 'discounts',
    name: 'website.discount.code',
    children: [
      {
        title: 'Code',
        name: 'website.discount.code',
        role: 'discounts',
      },
      {
        title: 'Automatic',
        name: 'website.discount.automatic',
        role: 'discounts',
      },
    ],
  },
  {
    title: 'Upsell',
    icon: 'mdi-basket',
    name: 'website.apps.upsell.list',
    type: 'many',
    role: 'apps',
    status: false,
    children: [
      {
        title: 'Pre/Post Purchase',
        name: 'website.apps.upsell.list',
        role: 'apps',
      },
      {
        title: 'Quantity Discounts',
        name: 'website.apps.quantity.list',
        role: 'apps',
      },
      {
        title: 'Bundles',
        name: 'website.apps.bundle.list',
        role: 'apps',
      },
    ],
  },
  {
    title: 'Sale Channel',
    icon: 'mdi-shopping',
    name: 'website.settings.sale-channels.facebook',
    type: 'many',
    role: 'settings',
    status: false,
    children: [
      {
        title: 'Facebook',
        name: 'website.settings.sale-channels.facebook',
        role: 'payment_providers',
      },
      {
        title: 'Google',
        name: 'website.settings.sale-channels.google',
        role: 'payment_providers',
      },
      {
        title: 'Tiktok',
        name: 'website.settings.sale-channels.tiktok',
        role: 'payment_providers',
      },
    ],
  },
  // {
  //   title: 'Share Pixel',
  //   icon: 'mdi-share',
  //   status: false,
  //   type: 'only',
  //   role: 'settings',
  //   name: 'website.pixel.sharePixel',
  // },
  {
    title: 'Customer',
    icon: 'mdi-folder-account',
    name: 'website.customer',
    children: [],
    role: 'customers',
    type: 'only',
  },
  {
    title: 'Settings',
    icon: 'mdi-settings',
    name: 'website.settings',
    children: [],
    type: 'only',
    role: 'settings',
  },
  {
    title: 'Online Store',
    icon: 'mdi-store',
    status: false,
    type: 'many',
    role: 'store',
    children: [
      {
        title: 'Themes',
        name: 'website.themes',
        role: 'themes',
      },
      {
        title: 'Pages',
        name: 'website.pages',
        role: 'pages',
      },
      {
        title: 'Navigation',
        name: 'website.menus',
        role: 'navigations',
      },
      {
        title: 'Domain',
        name: 'website.domain',
        role: 'domains',
      },
      {
        title: 'Preferences',
        name: 'website.preferences',
        role: 'preferences',
      },
      {
        title: 'Member',
        icon: 'mdi-account-group',
        name: 'website.member',
        type: 'only',
        role: 'homes',
      },
      {
        title: 'Size chart',
        name: 'website.sizechart',
        role: 'store',
      },
      // {
      //   title: 'DNS & Cache',
      //   name: 'website.dns',
      //   role: 'store',
      // },
    ],
  },
];

export default {
  menu,
  dropMenu,
};
