var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center align-center",staticStyle:{"width":"100%","height":"100vh"}},[_c('page-loader')],1):_c('v-main',[(_vm.currentRouteName === 'website.themes.editor')?_c('Editor'):_vm._e(),(_vm.currentRouteName === 'book-editor')?_c('BookEditor'):_vm._e(),(_vm.currentRouteName === 'book2-editor')?_c('BookEditor2'):_vm._e(),(_vm.currentRouteName === 'print-hub-editor')?_c('EditorPrintHub'):_vm._e(),(
      _vm.currentRouteName !== 'website.themes.editor' &&
        _vm.currentRouteName !== 'book-editor' &&
        _vm.currentRouteName !== 'book2-editor' &&
        _vm.currentRouteName !== 'print-hub-editor'
    )?_c('tool-bar',{attrs:{"current-sub":_vm.currentSub}}):_vm._e(),(
      _vm.currentRouteName !== 'website.themes.editor' &&
        _vm.currentRouteName !== 'book-editor' &&
        _vm.currentRouteName !== 'book2-editor' &&
        _vm.currentRouteName !== 'print-hub-editor'
    )?_c('v-container',{staticStyle:{"padding":"0px","height":"100%"},attrs:{"fluid":""}},[_c('div',{staticClass:"website-page "},[[_c('div',{class:_vm.checkClassContainer(),attrs:{"id":"container-custom"}},[(this.isLoading)?_c('div',[_c('Loading')],1):_c('div',[(_vm.currentRouteName === 'website' || _vm.currentRouteName === 'websitesa')?_c('Dashboard'):_c('router-view')],1)])]],2),_vm._t("default")],2):_vm._e(),_c('TrialBox')],1)}
var staticRenderFns = []

export { render, staticRenderFns }