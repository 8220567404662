<template>
  <div class="toolbar-page">
    <!-- <v-navigation-drawer
      permanent
      color="navi"
      :clipped="true"
      mobile-breakpoint="850"
      fixed
      app
      v-model="drawer"
      width="240"
      :mini-variant.sync="$store.state.navigation.mini"
    > -->
    <v-navigation-drawer
      color="navi"
      :clipped="true"
      mobile-breakpoint="850"
      app
      fixed
      v-model="drawer"
      width="240"
      :mini-variant.sync="$store.state.navigation.mini"
    >
      <v-list class="toolbar-mobile">
        <v-list-item class="d-flex justify-center">
          <span class="logo-app-toolbar-mobile">
            <img
              width="30px"
              :src="imageFake.Logo"
              onerror="this.onerror=null;this.src='https://minio.lattehub.com/img/720/0/resize/metadata/logo_lattex.png';"
            />
          </span>
        </v-list-item>
      </v-list>
      <!--  -->
      <v-list nav dense :class="`list-sidebar` + ($store.state.navigation.mini ? ' pt-10' : '')">
        <div class="ml-4 py-3 mt-5 " v-if="step.currentStep / step.totalSteps < 1">
          <div class="fz-15 font-weight-bold ">Get your shop ready</div>

          <v-progress-linear
            color="primary"
            class=" my-1"
            height="10"
            :value="(step.currentStep / step.totalSteps) * 100"
            striped
            rounded
            @click="showStep"
            @mouseover="showStep"
          ></v-progress-linear>
          <div>{{ step.currentStep }} / {{ step.totalSteps }}</div>
        </div>

        <div v-for="(item, i) in items" :key="i">
          <v-list-group
            v-if="
              item.children &&
                item.children.length > 0 &&
                (!item.role || (item.role && utilities.checkPermisson(item.role, user)))
            "
            :key="item.title"
            :expand="true"
            v-model="item.status"
            :value="activeGroup && item.title == activeGroup.title"
            no-action
          >
            <template v-if="item.title === 'Online Store'" v-slot:appendIcon>
              <v-icon @click="goToStore" class="icon-append-no-rotate" small>mdi-open-in-new</v-icon>
            </template>
            <!-- chứa list item (có children) -->
            <template v-slot:activator>
              <!-- <v-list-item-icon class="pl-3" style="width: 40px"> -->
              <v-list-item-icon @click.stop="$router.push({ name: item.children[0].name })">
                <v-icon class="fz-22">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title-sidebar">{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <!-- các children bên trong -->
            <router-link
              style="text-decoration: none"
              :to="
                utilities.checkPermisson(child.role, user)
                  ? { name: child.name, params: child.params || {} }
                  : { name: 'no-access' }
              "
              v-for="(child, i) in item.children"
              :key="i"
            >
              <v-list-item
                v-if="
                  ((child.name === 'website.product-personalized' && !isDropship) ||
                    child.name !== 'website.product-personalized') &&
                    (child.name != 'website.estimated-profit' || isVip)
                "
                :active-class="currentPage.includes(child.name) ? 'siderbar-active' : ''"
                :class="{ 'active-item': currentPage.includes(child.name) }"
                @click="
                  utilities.checkPermisson(child.role, user)
                    ? changePage({
                        name: child.name,
                      })
                    : $router.push({ name: 'no-access' })
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    :style="utilities.checkPermisson(child.role, user) ? `color:#ddddd !important` : ``"
                    class="title-sidebar title-sidebar-child"
                  >
                    {{ $t(child.title) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-group>
          <!-- lits item KHÔNG có children -->
          <router-link
            style="text-decoration: none"
            v-else
            :to="utilities.checkPermisson(item.role, user) ? { name: item.name } : { name: 'no-access' }"
          >
            <v-list-item
              :class="{ 'd-none': item.title == 'Balance' && !isDropship }"
              v-model="item.status"
              :active-class="currentPage == item.name ? 'siderbar-active' : ''"
              :exact="item.name === 'home' ? false : true"
              @click="
                utilities.checkPermisson(item.role, user)
                  ? changePage({ name: item.name })
                  : $router.push({ name: 'no-access' })
              "
            >
              <v-list-item-icon>
                <v-icon class="fz-22">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="title-sidebar">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="item.title == 'Balance'" style="color: #555;">
                {{ balance }}
              </v-list-item-action>
            </v-list-item>
          </router-link>
        </div>
      </v-list>
      <v-list
        nav
        dense
        :class="`list-sidebar` + ($store.state.navigation.mini ? ' pt-10' : '')"
        style="position: fixed; bottom: 1px; width: 100%; z-index: 100;padding: 0px"
      >
        <v-divider></v-divider>
        <v-menu v-if="user" offset-x>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="ml-2 align-center text-color--main pointer d-flex">
              <v-avatar v-if="user && user.profilePicture" size="30" class="mr-2">
                <img v-if="user && user.profilePicture" :src="imageHelpers.url(user.profilePicture)" alt="alt" />
              </v-avatar>
              <v-avatar v-else size="30" class="mr-2">
                <img :src="imageFake.user" alt="alt" />
              </v-avatar>
              <h4 class="text-color--main">
                <span>{{ user.email }}</span>
              </h4>
              <v-list-item-icon>
                <v-icon>mdi-menu-swap</v-icon>
              </v-list-item-icon>
            </span>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in itemsLink" :key="index" @click="choseItem(item)">
                <v-list-item-title class="pointer">{{ item.title }}</v-list-item-title>
              </v-list-item>
              <v-divider v-if="showBalance" />
              <v-list-item
                :key="balance"
                v-if="paymentChoice === 'latte' || showBalance === true"
                @click="$router.push({ name: 'website.settings.payout' })"
              >
                <v-list-item-title class="pointer">
                  <strong>Balance({{ balance }})</strong>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-list>
      <div @click.stop="" class="user-mini-variant" v-if="$store.state.navigation.mini">
        <i class="fas fa-user-alt"></i>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      v-if="!$store.state.navigation.mini"
      app
      fixed
      color="white"
      class="toolbar"
      height="55"
      :clipped-left="true"
    >
      <v-row no-gutters class="toolbar-container">
        <span class="logo-app-toolbar">
          <img
            width="30px"
            :src="imageFake.Logo"
            onerror="this.onerror=null;this.src='https://minio.lattehub.com/img/720/0/resize/metadata/logo_lattex.png';"
          />
        </span>
        <v-btn class="btn-show-toolbar" elevation="0" icon @click.stop="drawer = !drawer">
          <v-icon>fas fa-bars</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-menu v-if="user" offset-y>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="mr-4 align-center text-color--main pointer d-flex">
              <v-avatar v-if="user && user.profilePicture" size="30" class="mr-2">
                <img v-if="user && user.profilePicture" :src="imageHelpers.url(user.profilePicture)" alt="alt" />
              </v-avatar>
              <v-avatar v-else size="30" class="mr-2">
                <img :src="imageFake.user" alt="alt" />
              </v-avatar>
              <h4 class="text-color--main">
                <span>{{ user.email }}</span>
              </h4>
              <i class="fas fa-caret-down ml-2"></i>
            </span>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in itemsLink" :key="index" @click="choseItem(item)">
                <v-list-item-title class="pointer">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-row>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div
      class="onboarding-popup relative"
      style="top:35px ;  z-index: 10000000 !important;"
      v-if="detailStep && isShowStep"
    >
      <div @click="skipStep" class="fz-16 font-weight-bold">Next step: {{ detailStep.name }}</div>
      <div class="my-3">
        {{ detailStep.description }}
      </div>
      <div class="d-flex justify-space-between pt-3 align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" @click="clickStep" color="primary"
              >{{ utilities.stringLimit(detailStep.button, 50) }}
            </v-btn>
          </template>
          <span>{{ detailStep.name }}</span>
        </v-tooltip>

        <div @click="skipStep" v-if="detailStep.button !== `Back to Home`" class="pointer">Skip this step</div>
      </div>
      <span @click="isShowStep = false" class="close-step"> X</span>
    </div>
    <v-dialog
      v-if="proxiesList.length === 0 && showWarning && loadedProxy && enableRef"
      v-model="notifyProxy"
      width="480"
    >
      <v-card>
        <v-card-title>Notify</v-card-title>
        <v-card-text>
          Your store hasn't been configured with a payment gateway, please contact the administrator to set it up before
          publishing(run ads)!!!
        </v-card-text>
        <v-card-actions class="justify-end pr-4 pb-4">
          <v-btn @click="notifyProxy = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <MenuApps /> -->
  </div>
</template>

<script>
import { payoutService } from '@/apis/payout';
import currency from '@/helpers/currency';
import MENU from '@/const/mockData';
import { mapGetters, mapState } from 'vuex';
import { authService } from '@/apis/auth.s';
import { apiConfig } from '@/apis/config.js';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import verifyMixin from '@/mixins/verify';
import utilities from '@/helpers/utilities';
import event from '@/plugins/event-bus';
import { apiProxy } from '@/apis/proxy';
import STORAGE_NAME from '@/const/storage';
import { domainService } from '@/apis/domain.s';

var timer;
var loadProxy;
export default {
  mixins: [verifyMixin],
  components: {},
  props: {},
  data() {
    return {
      isShowStep: false,
      utilities,
      indexItem: '',
      drawer: true,
      mini: true,
      items: MENU.menu,
      showWarning: true,
      notifyProxy: true,
      imageFake,
      imageHelpers,
      detailStep: {},
      proxiesList: [],
      loadedProxy: false,
      step: {},
      enableRef: false,
      isVip: false,
      itemsLink: [
        { title: 'Profile', router: 'website.profile', params: {}, icon: '' },
        { title: 'Select another shop', router: 'auth', params: { authPage: 'shop' }, icon: '' },
        { title: 'Open your site', router: '', params: {}, icon: '' },
        { title: 'Logout', router: 'logout', params: {}, icon: '' },
      ],
      paymentChoice: null,
      showImportFunc: false,
      showBalance: false,
      currentSub: null,
      SPECIAL_SUBSCRIPTION: 'LT_DROP_VIP',
      SPECIAL_SUBSCRIPTIONS: ['LT_DROP_VIP', 'LP_CHECK'],
    };
  },
  async created() {
    this.getStep();
    event.$on('getStepStatus', message => {
      this.getStep();
    });
    this.checkEnableRef();
    this.updateProxyList();
  },
  mounted() {
    this.updateBalance();
    this.items = this.isDropship ? MENU.dropMenu : MENU.menu;
    this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    if (this.pagePreference && this.pagePreference.businessType === 'dropship') {
      this.isDrop = true;
    }
    this.showImportFunc = this.paymentChoice === 'seller' || (this.paymentChoice === 'latte' && !this.isDrop);
  },
  methods: {
    async loadBalance() {
      try {
        if (false == this.showBalance) return;
        let res = await payoutService.getBalance();
        this.$store.commit('pagePreference/setBalance', res.data?.availableToPayout || 0);
      } catch (err) {}
    },
    async updateBalance() {
      if (timer) clearTimeout(timer);
      await this.loadBalance();
      timer = setTimeout(() => {
        this.updateBalance();
      }, 900000);
    },
    async loadProxy() {
      const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
      const { data } = await apiProxy.get({
        limit: 10,
        page: 1,
        storeId: storeId,
      });
      this.proxiesList = data?.proxies;
      this.loadedProxy = true;
    },
    async updateProxyList() {
      if (loadProxy) clearTimeout(loadProxy);
      this.showWarning = true;
      this.notifyProxy = true;
      await this.loadProxy();
      if (this.proxiesList.length === 0) {
        loadProxy = setTimeout(() => {
          this.updateProxyList();
        }, 120000);
      }
    },
    async getStep() {
      try {
        let res = await apiConfig.getStatus();
        this.detailStep = res.data.currentConfig;
        if (this.detailStep.field === `domainConfigured`) {
          this.detailStep.description = this.detailStep.description.replace(
            `{domain}`,
            localStorage.getItem(`store-domain`),
          );
        }
        this.step = res.data;
        this.isShowStep = true;
      } catch (error) {}
    },
    clickStep() {
      this.$router.push({ path: `/${this.detailStep.path}` });
      this.isShowStep = false;
    },
    async checkEnableRef() {
      try {
        const checkEnableRef = await domainService.checkEnableRef();
        if (checkEnableRef) {
          this.enableRef = checkEnableRef.data[0].enableRef;
          this.isVip = checkEnableRef.data[0].isVip;
        }
      } catch (error) {
        this.errors = error;
      }
    },
    async skipStep() {
      try {
        if (this.detailStep.button !== `Back to Home`) {
          let res = await apiConfig.skipStep();
          this.detailStep = res.data.currentConfig;
          this.step = res.data;
        } else {
          this.isShowStep = false;
        }
      } catch (error) {
        console.log('error', 'error', error);
      }
    },
    showStep() {
      this.isShowStep = !this.isShowStep;
    },
    openChildren(i) {
      console.log(1);
    },
    changePage(name) {
      this.$router.push(name);
      this.auth();
    },
    goToStore() {
      window.open('https://' + this.$store.state.auth.accountInfo.rDomain);
    },
    choseItem(item) {
      if (item.title === 'Logout') {
        try {
          authService.logout();
          localStorage.clear();
          window.location.href = this.$urlAuth;
        } catch (error) {
          console.log(error.response);
        }
      } else if (item.title === 'Open your site') {
        window.open('https://' + this.$store.state.auth.accountInfo.rDomain);
      } else {
        this.$router.push({ name: item.router, params: item.params });
      }
    },
  },
  watch: {
    '$store.state.pagePreference.preference'() {
      let paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
      this.showImportFunc = paymentChoice === 'seller' || (paymentChoice === 'latte' && !this.isDrop);
      this.showBalance =
        paymentChoice === 'latte' &&
        this.pagePreference.businessType === 'dropship' &&
        this.pagePreference.isTrademark === true;
      if (this.pagePreference.businessType === 'dropship') {
        this.items = MENU.dropMenu;

        if (this.SPECIAL_SUBSCRIPTIONS.includes(this.currentSub?.currentSubscription?.code)) {
          this.isVip = true; //to show estimate profit
          const itemSharePixel = {
            title: 'Share Pixel',
            icon: 'mdi-share',
            status: false,
            type: 'only',
            role: 'settings',
            name: 'website.pixel.sharePixel',
          };
          const itemPayout = {
            title: 'Payout',
            icon: 'mdi-credit-card-outline',
            status: false,
            type: 'only',
            role: 'settings',
            name: 'website.payout',
          };
          if (this.items.length === 9) {
            this.items.splice(5, 0, itemSharePixel);
            this.items.splice(6, 0, itemPayout);
          } else {
            this.items.splice(7, 0, itemSharePixel);
            this.items.splice(8, 0, itemPayout);
          }
        }
      }
    },
    '$store.state.subscription.currentSubscription.code'() {
      console.log('this.items', this.items.length);
      this.currentSub = this.$store.state.subscription;
      if (this.SPECIAL_SUBSCRIPTIONS.includes(this.currentSub?.currentSubscription?.code)) {
        this.isVip = true; //to show estimate profit

        const itemSharePixel = {
          title: 'Share Pixel',
          icon: 'mdi-share',
          status: false,
          type: 'only',
          role: 'settings',
          name: 'website.pixel.sharePixel',
        };
        const itemPayout = {
          title: 'Payout',
          icon: 'mdi-credit-card-outline',
          status: false,
          type: 'only',
          role: 'settings',
          name: 'website.payout',
        };
        if (this.items.length === 9) {
          this.items.splice(5, 0, itemSharePixel);
          this.items.splice(6, 0, itemPayout);
        } else {
          this.items.splice(7, 0, itemSharePixel);
          this.items.splice(8, 0, itemPayout);
        }
      }
    },
  },
  computed: {
    balance() {
      let val = this.$store.state.pagePreference.balance || 0;
      return currency.priceFormat(val);
    },
    isDropship() {
      return this.pagePreference && this.pagePreference.businessType === 'dropship';
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
    ...mapGetters(['user']),
    fullName: function() {
      return this.user.firstName + ' ' + this.user.lastName || '';
    },
    currentPage: function() {
      // console.log(this.$route);.toolbar-page .list-sidebar .title-sidebar
      return this.$route.name;
    },
    activeGroup: function() {
      // dùng để filter $route.name nào  === với name trong mockData thì toggle nó xuống
      let group = this.items.find(
        item =>
          item.name == this.currentPage ||
          (item.children &&
            item.children.find(item => item.name == this.currentPage || this.currentPage.includes(item.name))),
      );
      if (group?.title == 'Online Store') {
        group.status = true;
      }
      if (
        group?.name == 'website.discount.code' ||
        group?.name == 'website.orders' ||
        group?.name == 'website.analytics' ||
        group?.name == 'website.products'
      )
        group.status = true;
      return group;
    },
  },
};
</script>
<style lang="scss">
.close-step {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.toolbar-page {
  .user-mini-variant {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;

    i {
      cursor: pointer;
      font-size: 20px;
      font-weight: normal;
    }
  }

  .v-navigation-drawer__content {
    position: relative;
  }

  .button-logout {
    position: absolute;
    bottom: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #ffffff;
    align-items: center;
  }

  .v-list-item__icon {
    margin-right: 0px !important;
  }

  .v-list--nav {
    padding-left: 8px;
    padding-right: 8px;
  }

  .siderbar-active {
    opacity: 0.9;
    background: $main-color;
    border-left: 2px solid $main-color;

    .v-list-item__title {
      color: $main-color;
      font-weight: bold;
    }

    .v-list-item__icon {
      color: $main-color;
    }
  }

  .v-list-item--link:before {
    background-color: $main-color !important;
  }

  .active-item:before {
    background-color: $main-color !important;
    opacity: 0.2;
  }

  .list-sidebar {
    .title-sidebar-child {
      font-size: 12px !important;
      padding-left: 28px;
      text-decoration: none;
    }

    .title-sidebar {
      display: block;
      // vị trí chữ so với hàng dọc của dòng
      // vertical-align: middle;
      margin: 0 0 0 16px;
      color: #6e7a81;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      white-space: nowrap;
      width: 100%;
    }
  }

  .theme--light.v-list-item:hover:before {
    opacity: 0.2;
  }

  .toolbar {
    height: $height-header-main;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.15) !important;
    background-color: #fff;

    .toolbar-container {
      display: flex;
      width: 100%;
      align-items: center;

      input {
        outline: none;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 8px;
        width: 100%;
        max-width: 650px;
        background: #f1f2f3;
      }

      .logo-app-toolbar {
        width: 240px;
        @include flex();

        img {
          width: 120px;
          margin-right: 120px;
        }
      }
    }
  }

  .toolbar-mobile {
    display: none;
  }

  .btn-show-toolbar {
    display: none;
  }

  @media only screen and (max-width: 850px) {
    .login-form {
      width: 100% !important;
    }
    .toolbar {
      .btn-show-toolbar {
        display: block;
      }
    }
    .logo-app-toolbar {
      display: none !important;
    }
    .toolbar-mobile {
      padding: 0;
      height: $height-header-main;
      width: 240px;
      border-bottom: 1px solid #ddd;
      @include flex();

      .logo-app-toolbar-mobile {
        width: 240px;
        @include flex();

        img {
          width: 120px;
          margin-right: 90px;
        }
      }
    }
  }

  .icon-append-no-rotate {
    transform: none !important;
  }

  .v-navigation-drawer--fixed {
    z-index: 200 !important;
  }
}

.fz-22 {
  font-size: 22px !important;
}

.rt-link {
  text-decoration: none;
}

.onboarding-popup {
  position: fixed;
  border: 1px solid rgba(54, 62, 67, 0.04);
  background-color: #fff;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  left: 500px;
  -webkit-box-shadow: 0 6px 16px 0 rgb(54 62 67 / 10%), 0 14px 40px -4px rgb(54 62 67 / 8%);
  box-shadow: 0 6px 16px 0 rgb(54 62 67 / 10%), 0 14px 40px -4px rgb(54 62 67 / 8%);
  border-radius: 12px;
  width: 500px;
  z-index: 999999999999999999999999999;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);

  &:after {
    content: ' ';
    width: 0;
    height: 0;
    border-right: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    position: absolute;
    left: -6px;
    top: 50%;
  }
}
</style>
