<template>
  <div style="width: 100%; height: 100vh" v-if="isLoading" class="d-flex justify-center align-center">
    <page-loader />
  </div>
  <v-main v-else>
    <Editor v-if="currentRouteName === 'website.themes.editor'" />
    <BookEditor v-if="currentRouteName === 'book-editor'" />
    <BookEditor2 v-if="currentRouteName === 'book2-editor'" />
    <EditorPrintHub v-if="currentRouteName === 'print-hub-editor'" />
    <tool-bar
      :current-sub="currentSub"
      v-if="
        currentRouteName !== 'website.themes.editor' &&
          currentRouteName !== 'book-editor' &&
          currentRouteName !== 'book2-editor' &&
          currentRouteName !== 'print-hub-editor'
      "
    />
    <v-container
      v-if="
        currentRouteName !== 'website.themes.editor' &&
          currentRouteName !== 'book-editor' &&
          currentRouteName !== 'book2-editor' &&
          currentRouteName !== 'print-hub-editor'
      "
      fluid
      style="padding: 0px; height: 100%"
    >
      <div class="website-page ">
        <template>
          <div id="container-custom" :class="checkClassContainer()">
            <!-- <loading-component /> -->
            <div v-if="this.isLoading">
              <Loading />
            </div>
            <div v-else>
              <Dashboard v-if="currentRouteName === 'website' || currentRouteName === 'websitesa'" />
              <router-view v-else />
            </div>
          </div>
        </template>

        <!-- <v-row justify="center" no-gutters class="container"> Tao web o day </v-row> -->
      </div>
      <slot />
    </v-container>
    <TrialBox />
  </v-main>
</template>

<script>
import STORAGE_NAME from '@/const/storage';
import Editor from './themes/Editor';
// import HomeIndex from './home';
import ToolBar from './layouts/ToolBar';
import TrialBox from './components/TrialBox.vue';
import { mapMutations } from 'vuex';
import { authService } from '@/apis/auth.s';
import { userService } from '@/apis/user.s';
import verifyMixin from '@/mixins/verify';
import LoginVue from '../auth/components/Login.vue';
import storeRequest from '@/apis/request/storeRequest';
import accountRequest from '@/apis/request/accountRequest';
import { domainService } from '@/apis/domain.s';
import $ from 'jquery';
import event from '@/plugins/event-bus';
import { FETCH_PAGE_PREFERRENCE } from '@/store/actions.type';
import Loading from '@/components/Loading.vue';
import { personalizesApi } from '@/apis/personalizes';
import utilities from '@/helpers/utilities';
import { mapGetters } from 'vuex';

const BookEditor = () => import('@/views/book-editor');
const BookEditor2 = () => import('@/views/book2');
const EditorPrintHub = () => import('@/views/website/print-hub/editor');
import Dashboard from './analytics';

export default {
  components: {
    ToolBar,
    // HomeIndex,
    Editor,
    Loading,
    BookEditor,
    BookEditor2,
    EditorPrintHub,
    TrialBox,
    Dashboard,
  },
  mixins: [verifyMixin],
  data() {
    return {
      utilities,
      fonts: [],
      isLoading: true,
      countResquest: 0,
      executor: null,
      currentSub: null,
    };
  },
  mounted() {
    if (
      window.localStorage.getItem('r_domain') &&
      window.localStorage.getItem('r_domain') !== undefined &&
      window.localStorage.getItem('r_domain') !== 'null' &&
      window.localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN) &&
      window.localStorage.getItem(STORAGE_NAME.STORE_ID)
    ) {
      return;
    } else {
      this.logout();
    }
  },
  async created() {
    this.setData();
    this.isLoading = true;
    let checkAuth = await this.auth();
    setInterval(async () => {
      try {
        await this.auth();
      } catch (e) {}
    }, 23 * 60 * 60 * 1000);

    this.checkRouter();
    event.$on('error-api', message => {
      this.$store.commit('setMessages', {
        messages: message ? message.join(',') : 'Oops! Unknown error!',
        type: 'error',
      });
    });
    event.$on('success-api', message => {
      this.$store.commit('setMessages', {
        messages: message ? message.join(',') : 'Success',
        type: 'success',
      });
    });
    await this.getUserInfo();
    await this.$store.dispatch(FETCH_PAGE_PREFERRENCE);
    localStorage.setItem(`nameTheme`, this.$store.state.pagePreference.preference.storeFrontDefaultTheme);
    this.getFontPersonalize();
    await this.$store.dispatch('getCurrentSubscription');

    this.currentSub = this.$store.state.subscription;
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(['user']),
    currentRouteName() {
      return this.$route.name;
    },
    pagePreference() {
      return this.$store.getters.pagePreference;
    },
  },
  //TO DO

  methods: {
    async getFontPersonalize() {
      try {
        this.fonts = (await personalizesApi.getFont()).data;
        for (let index = 0; index < this.fonts.length; index++) {
          const element = this.fonts[index];
          $('head').append(
            `<style type="text/css">@font-face { font-family: '${element.name}' ; src: url(${element.fontUrl}); }</style>`,
          );
        }
      } catch (error) {}
    },
    checkClassContainer() {
      if (this.currentRouteName === 'website.products.bulkEdit') {
        return 'website-container-bulk-update';
      }
      if (this.currentRouteName === 'website.sales-reports') {
        return 'container container--fluid';
      }
      if (this.currentRouteName === 'website.products.create') {
        return 'container container--fluid';
      }
      if (this.currentRouteName === 'website.products.update') {
        return 'container container--fluid';
      }
      if (this.currentRouteName.includes('website.apps.')) {
        return 'app-container';
      } else {
        return 'website-container';
      }
    },
    async getDomain() {
      let domainData = await domainService.list();
      // let getvsv = await productApi.getSVProduct();
      // console.log('getvsv', getvsv);
      if (domainData.data && domainData.data.length > 0) {
        let domainPrimary = domainData.data.find(item => item.domainType === 'SystemDefault');
        localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, domainPrimary.host);
      }
    },
    ...mapMutations(['setUser']),
    checkRouter() {
      if (this.currentRouteName.includes('website.apps.')) {
        this.$store.commit('setNavigationMini', true);
      } else {
        this.$store.commit('setNavigationMini', false);
      }
      // console.log(
      //   'this.utilities.checkPermisson(`products`, this.user)',
      //   this.utilities.checkPermisson(`products`, this.user),
      // );
    },
    async getUserInfo() {
      try {
        console.log(this.isLoading);
        let data = await userService.get();
        if (data) {
          let per = await this.getPermission(data.data.id);
          if (per?.scopes) {
            console.log(this.isLoading);

            this.setUser({
              ...data.data,
              isStoreOwner: per.isStoreOwner ? per.isStoreOwner : false,
              isStoreAdmin: per.isStoreAdmin ? per.isStoreAdmin : false,
              scopes: per.scopes,
            });
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(data.data));
            !this.utilities.checkPermisson(this.utilities.checkRouter(this.$route), this.user)
              ? this.$router.push({ name: 'no-access' })
              : null;
          } else {
            this.logout();
          }
        }
      } catch (error) {
        console.log('error', error);
        this.auth();
        // location.reload();
      }
      // this.isLoading = false;
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.checkRouter();
        let url = window.location.href.split('/');
        document.title =
          url[2]
            .split('.')[0]
            .charAt(0)
            .toUpperCase() +
            url[2].split('.')[0].slice(1) +
            ' ~ Lattehub' || 'Lattehub';
      },
    },

    currentRouteName() {
      if (this.currentRouteName === 'website.products.bulkEdit') {
        var element = document.getElementById('container-custom');
        // console.log(element);
        element.classList.remove('website-container').addClass('container-fluid');
      }
    },
  },
};
</script>

<style lang="scss">
.website-page {
  background-color: $background-page;
  @include flex(center, flex-center);
  height: 100%;
  margin-bottom: 56px;

  & .website-container {
    max-width: 1024px;
    // margin: 0.5rem 1rem;
    padding: 30px;
    // background-color: #fff;
    width: 100%;
    // height: auto;
  }

  & .website-container-bulk-update {
    max-width: 10000px;
    // max-width: 100%;
    width: 100% !important;
    background-color: white !important;
    @include flex(center, flex-center);
  }

  & .app-container {
    flex: none;
    // max-width: 100%;
    width: 100% !important;
    height: 100%;
    background-color: white !important;
    // @include flex(center, flex-center);
  }
}
</style>
