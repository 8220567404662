import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/';

function getStatus(params) {
  return storeRequest.get(`${path}config/status`);
}

function skipStep() {
  return storeRequest.put(`${path}config/skip`);
}

export const apiConfig = {
  getStatus,
  skipStep,
};
